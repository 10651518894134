import { request, authRequest } from "../../utils/api";
import qs from "querystringify";

export const SHOW_APP_MESSAGE = "SHOW_APP_MESSAGE";
export const SHOW_APP_MODAL = "SHOW_APP_MODAL";
export const OPEN_REQUEST = "OPEN_REQUEST";
export const CLOSE_REQUEST = "CLOSE_REQUEST";
export const SET_TAG_GROUPS = "SET_TAG_GROUPS";
export const SET_REDIRECT = "SET_REDIRECT";
export const FIRE_EVENT = "FIRE_EVENT";
export const SET_REPORTS = "SET_REPORTS";
export const APPROVE_ALL_REPORTS = "APPROVE_ALL_REPORTS";
export const DECLINE_ALL_REPORTS = "DECLINE_ALL_REPORTS";
export const SET_ELE_REWARDED_RECORDS = "SET_ELE_REWARDED_RECORDS";
export const SET_USERS = "SET_USERS";
export const SET_APPLICATIONS = "SET_APPLICATIONS";

export const showAppMessage = (text, level = "info") => {
  return (dispatch) => {
    dispatch({ type: SHOW_APP_MESSAGE, message: { text, level } });
    setTimeout(() => {
      dispatch({ type: SHOW_APP_MESSAGE, message: null });
    }, 200);
  };
};

export const showAppModal = (modal) => {
  return { type: SHOW_APP_MODAL, modal: modal };
};

export const setRedirect = (url) => {
  return {
    type: SET_REDIRECT,
    url,
  };
};

export const requireAuth = () => {
  return (dispatch) => {
    dispatch(showAppMessage("请先登录", "warning"));
    dispatch(showAppModal("PhoneSigninModal"));
  };
};

export const openRequest = (key) => {
  return {
    type: OPEN_REQUEST,
    key: key,
  };
};

export const closeRequest = (key) => {
  return {
    type: CLOSE_REQUEST,
    key: key,
  };
};

export const fetchTagGroups = () => {
  return (dispatch) => {
    request({
      url: "/tag_groups",
    })
      .then((res) => {
        dispatch({
          type: SET_TAG_GROUPS,
          tagGroups: res.data.tag_groups,
        });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message, "error"));
      });
  };
};

export const fetchReports = (token, status = "pending", nosystem = "false") => {
  return (dispatch) => {
    authRequest(token, {
      url: `/report_contents?status=${status}&nosystem=${nosystem}`,
    }).then((res) => {
      dispatch({
        type: SET_REPORTS,
        reports: res.data.reports,
      });
    });
  };
};

export const approveAllReports = (
  token,
  ids,
  note,
  firstEle = 3,
  restEle = 1
) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/report_contents/approve_all`,
      method: "POST",
      data: {
        ids,
        note,
        first_ele: firstEle,
        rest_ele: restEle,
      },
    })
      .then((res) => {
        dispatch(showAppMessage("通过成功", "success"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const declineAllReports = (token, ids, note) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/report_contents/decline_all`,
      method: "POST",
      data: { ids, note },
    })
      .then((res) => {
        dispatch(showAppMessage("驳回成功", "success"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const approveAllSysReports = (token) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/report_contents/approve_all_sys`,
      method: "POST",
      data: {},
    })
      .then((res) => {
        dispatch(showAppMessage("处理成功", "success"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

/**
 * Categories
 */
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_FOLLOWED_CATEGORIES = "SET_FOLLOWED_CATEGORIES";
export const CHECK_CATEGORY_FLAGS = "CHECK_CATEGORY_FLAGS";

export const fetchCategory = (id) => {
  return request({ url: `/categories/${id}` }).then((res) => res.data);
};

/**
 * 获取版块的最近活跃用户
 *
 * @param {number} id - 版块的ID
 * @returns {Primise} 返回一个 Promise 对象，resolve 时返回最近活跃用户列表
 */
export const fetchCategoryActiveUsers = (id) => {
  return request({ url: `/categories/${id}/active_users` }).then(
    (res) => res.data.data
  );
};

export const fetchCategories = () => {
  return request({ url: "/categories?show_paid=true" }).then(
    (res) => res.data.categories
  );
};

export const getCategories = () => {
  return (dispatch) => {
    dispatch(openRequest(GET_CATEGORIES));
    fetchCategories()
      .then((categories) => {
        dispatch({ type: SET_CATEGORIES, categories });
        dispatch(closeRequest(GET_CATEGORIES));
      })
      .catch(() => {
        dispatch(closeRequest(GET_CATEGORIES));
      });
  };
};

export const fetchFollowedCategories = (userId) => {
  return request({ url: `/users/${userId}/categories` }).then(
    (res) => res.data.categories
  );
};

export const getFollowedCategories = (userId) => {
  return (dispatch) => {
    fetchFollowedCategories(userId)
      .then((categories) => {
        dispatch({
          type: SET_FOLLOWED_CATEGORIES,
          followedCategories: categories,
        });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const checkCategoryFlags = (token, flags, ids) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/categories/check",
      method: "POST",
      data: { flags, ids },
    })
      .then((res) => {
        dispatch({ type: CHECK_CATEGORY_FLAGS, flags: res.data });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

/*
 * 关注板块
 *
 * 关注成功后，返回更新的板块详情，用于前端更新
 */
export const followCategory = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/categories/${id}/follow`,
      method: "POST",
    })
      .then(() => fetchCategory(id))
      .then((category) => {
        dispatch(showAppMessage("关注成功", "success"));
        return category;
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

/*
 * 取消关注板块
 *
 * 取消成功后，返回更新的板块详情，用于前端更新
 */
export const unfollowCategory = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/categories/${id}/follow`,
      method: "DELETE",
    })
      .then(() => fetchCategory(id))
      .then((category) => {
        dispatch(showAppMessage("取关成功", "success"));
        return category;
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const fireEvent = (name, data) => {
  return (dispatch) => {
    dispatch({ type: FIRE_EVENT, event: { name, data, ts: new Date() } });
    setTimeout(() => {
      dispatch({ type: FIRE_EVENT, event: {} });
    }, 0);
  };
};

/**
 * Signature
 */
export const getWechatSignature = (url) => {
  return request({
    url: "/wechat/signature",
    params: { url },
  })
    .then((res) => res.data)
    .catch((err) => {
      console.warn("failed to get wechat signature", err);
    });
};

/**
 广告
 */
export const SET_PROMOS = "SET_PROMOS";
export const getPromos = () => {
  return (dispatch) => {
    request({
      url: `/promos?kind=all`,
    }).then((res) => {
      dispatch({
        type: SET_PROMOS,
        promos: res.data.promos,
      });
    });
  };
};

export const getPromo = (id) => {
  return request({
    url: `/promos/${id}`,
  }).then((res) => res.data.promo);
};

/**
 * Blogrolls
 */
export const SET_BLOGROLLS = "SET_BLOGROLLS";
export const getBlogrolls = () => {
  return (dispatch) => {
    request({
      url: "/blogrolls",
    }).then((res) => {
      let blogrolls = [];
      let blogroll_categories = res.data.blogroll_categories;
      for (let index = 0; index < blogroll_categories.length; index++) {
        const element = blogroll_categories[index];
        if (element.id === 1) {
          blogrolls = element.blogrolls;
          break;
        }
      }
      dispatch({ type: SET_BLOGROLLS, blogrolls: blogrolls });
    });
  };
};

/**
 * Blogrolls Categories
 */
export const fetchBlogrollsCategories = () => {
  return request({
    url: "/blogrolls",
  }).then((res) => res.data.blogroll_categories);
};

/**
 * getLevels
 */
export const SET_LEVELS = "SET_LEVELS";
export const getLevels = () => {
  return (dispatch) => {
    request({
      url: "/levels",
    }).then((res) => {
      dispatch({ type: SET_LEVELS, levels: res.data.levels });
    });
  };
};

/**
 * Preferences
 */
export const SET_PREFS = "SET_PREFS";
export const getPrefs = () => {
  return (dispatch) => {
    request({
      url: "/preferences",
    }).then((res) => {
      dispatch({ type: SET_PREFS, prefs: res.data.preferences });
    });
  };
};

// 请求电量商品数据。
export const getEleProducts = () => {
  return request({
    url: `/products`,
  }).then((res) => res.data.products);
};

/**
 * get site tags
 */
export const fetchSiteTagGroups = () => {
  return request({
    url: `/tag_groups`,
  }).then((res) => res.data.tag_groups);
};

// export const fetchCities = () => {
//   return request({
//     url: "/tag_groups/city_jobs",
//   }).then((res) => res.data.cities);
// };
/**
 * getGroups
 */
export const SET_GROUPS = "SET_GROUPS";
export const getGroups = (groupCategoryId) => {
  return (dispatch) => {
    request({
      url: "/groups",
      params: {
        group_category_id: groupCategoryId,
      },
    }).then((res) => {
      dispatch({ type: SET_GROUPS, groups: res.data.groups });
    });
  };
};

/**
 * getGroupCategories
 */
export const SET_GROUP_CATEGORIES = "SET_GROUP_CATEGORIES";
export const getGroupCategories = () => {
  return (dispatch) => {
    request({ url: "/group_categories" }).then((res) => {
      dispatch({
        type: SET_GROUP_CATEGORIES,
        groupCategories: res.data.group_categories,
      });
    });
  };
};

/**
 * set report type and id
 * type: Post Comment  User
 */
export const SET_REPORT_PARAMS = "SET_REPORT_PARAMS";
export const setReport = (report) => {
  return {
    type: SET_REPORT_PARAMS,
    report,
  };
};

export const SET_SLIDERS = "SET_SLIDERS";
export const getSliders = (id) => {
  return (dispatch) => {
    request({
      url: "/sliders",
    }).then((res) => {
      dispatch({
        type: SET_SLIDERS,
        sliders: res.data.sliders,
      });
    });
  };
};

export const fetchEleRecords = (token, page = 1) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/ele_rewards?page=${page}`,
    }).then((res) => {
      dispatch({
        type: SET_ELE_REWARDED_RECORDS,
        records: res.data,
      });
    });
  };
};

export const createEleRewardedRecords = (token, params, callback) => {
  return (dispatch) => {
    authRequest(token, {
      url: "/ele_rewards",
      method: "POST",
      data: {
        ele_record: {
          user_id: params.userId,
          ele: params.ele,
          reason: params.reason,
          kind: params.kind,
        },
      },
    }).then((res) => {
      dispatch(showAppMessage("添加成功", "success"));
      callback();
    });
  };
};

export const getKeywords = (params) => {
  return request({
    url: `/keywords`,
    params: params,
  }).then((res) => {
    const keywords = res.data;
    return keywords;
  });
};

export const SET_APPLICATION_PARAMS = "SET_APPLICATION_PARAMS";
export const setApplication = (application) => {
  return {
    type: SET_APPLICATION_PARAMS,
    application,
  };
};

export const fetchApplications = (token, status = "pending") => {
  return (dispatch) => {
    authRequest(token, {
      url: `/applications?status=${status}`,
    }).then((res) => {
      dispatch({
        type: SET_APPLICATIONS,
        applications: res.data.applications,
      });
    });
  };
};
export const approveApplication = (token, id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/applications/${id}/approve`,
      method: "POST",
    })
      .then((res) => {
        dispatch(showAppMessage("通过成功", "success"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const declineApplication = (token, id, disposed_note) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/applications/${id}/decline`,
      method: "POST",
      data: { disposed_note },
    })
      .then((res) => {
        dispatch(showAppMessage("驳回成功", "success"));
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const SET_PENDING_COUNTS = "SET_PENDING_COUNTS";
export const fetchPendingCounts = (token) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/pending_counts`,
      method: "GET",
    }).then((res) => {
      dispatch({
        type: SET_PENDING_COUNTS,
        pendingCountsResult: res.data,
      });
    });
  };
};

export const fetchCities = (token) => {
  return authRequest(token, {
    url: "/cities",
    method: "GET",
  }).then((res) => {
    return res.data.cities;
  });
  // return request({ url: "/cities" }).then((res) => res.data.cities);
};

export const fetchCity = (id) => {
  return request({ url: `/cities/${id}` }).then((res) => res.data.city);
};

export const fetchCityEvents = () => {
  return request({ url: "/city_events" }).then((res) => res.data.city_events);
};

export const fetchSliders = (params) => {
  return request({
    url: `/sliders?kind=${params.kind}&listed=${params.listed}&city_id=${params.city_id}`,
  }).then((res) => res.data.sliders);
};

export const fetchCollaborationSliders = (params) => {
  const queryString = qs.stringify(params);
  return request({
    url: `/sliders?${queryString}`,
  }).then((res) => res.data.sliders);
};

export const fetchCityFollowers = (city_id, page, limit) => {
  return request({
    url: `/cities/${city_id}/followers?page=${page}&limit=${limit}`,
  }).then((res) => res.data);
};

export const fetchCityGroups = (city_id) => {
  return request({
    url: `/cities/${city_id}/groups`,
  }).then((res) => res.data.groups);
};

export const CHECK_FOLLOWED_CITIES = "CHECK_FOLLOWED_CITIES";
export const checkFollowedCities = (token) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/cities/followed",
      method: "GET",
    })
      .then((res) => {
        dispatch({
          type: CHECK_FOLLOWED_CITIES,
          followedCities: res.data.followed,
        });
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const followCity = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/cities/${id}/follow`,
      method: "POST",
    })
      .then((res) => {
        dispatch(showAppMessage("加入成功", "success"));
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const unfollowCity = (token, id, callback) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/cities/${id}/follow`,
      method: "DELETE",
    })
      .then((res) => {
        dispatch(showAppMessage("退出成功", "success"));
        callback();
      })
      .catch((err) => {
        dispatch(showAppMessage(err.message));
      });
  };
};

export const SET_POST_CHECKED_TIME = "SET_POST_CHECKED_TIME";
export const setPostCheckedTime = (postCheckedTime) => {
  return {
    type: SET_POST_CHECKED_TIME,
    postCheckedTime,
  };
};

export const fetchCollaborations = (token) => {
  return request({
    url: "/collaborations",
    method: "GET",
  }).then((res) => {
    return res.data.collaborations;
  });
};

export const SET_COLLABORATION = "SET_COLLABORATION";
export const fetchCollaboration = (token, category_id) => {
  return (dispatch) => {
    return authRequest(token, {
      url: `/collaborations?category_id=${category_id}`,
      method: "GET",
    }).then((res) => {
      dispatch({
        type: SET_COLLABORATION,
        collaboration:
          res.data.collaborations.length > 0
            ? res.data.collaborations[0]
            : null,
      });
    });
  };
};

/**
 * Features
 */

/*
 * 获取用户可用的 features
 *
 * @param {string} token - 用户的 token
 * @returns {Promise} 返回一个 Promise 对象，resolve 时返回用户可用的 features
 */
export const fetchFeatures = (token) => {
  return authRequest(token, {url: "/features" }).then((res) => res.data.data);
}